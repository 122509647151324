import { useContext, useState, useRef, useMemo } from "react";
import { AuthModulePayload } from "@riseart/client";
import { RiseArtApiClientContext } from "../context/Context";

export function useAuthorize(): Array<any> {
  const { client } = useContext(RiseArtApiClientContext);

  const [data, setData] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const instance = useMemo(() => client, [client]);

  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  async function processRequest(parameters: AuthModulePayload) {
    try {
      if (!instance) {
        throw new Error("Inalid API client provided.");
      }

      setLoading(true);
      setError(null);

      const response = await instance.authorize(parameters);

      setData(response);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return [processRequest, { data, error, loading, cancel }];
}
