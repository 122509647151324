import { AxiosError } from "axios";

/**
 * ErrorDetails
 */
type ErrorDetails = {
  requestUri: string | undefined;
  requestMethod: string | undefined;
  requestData: any;
  responseStatus: number | undefined;
  responseHeaders: any;
  responseData: any;
  originalError?: AxiosError | undefined;
};

/**
 * Codes
 */
class Codes {
  static INVALID_CONFIG = "APIC_1001";
  static INVALID_ENDPOINT = "APIC_1002";
  static INVALID_HEADER = "APIC_1003";
  static INVALID_HTTP_CONFIG = "APIC_1004";
  static INVALID_PARAMETER = "APIC_1005";
  static INVALID_TOKEN = "APIC_1006";
  static INVALID_VERSION = "APIC_1007";
  static NETWORK_ERROR = "APIC_1008";
  static API_SERVER_ERROR = "APIC_1009";
}

/**
 * Types
 */
class Types {
  static INVALID_CONFIG = "InvalidConfiguration";
  static INVALID_ENDPOINT = "InvalidEndpoint";
  static INVALID_HEADER = "InvalidHeader";
  static INVALID_HTTP_CONFIG = "InvalidHttpClientConfiguration";
  static INVALID_PARAMETER = "InvalidParameter";
  static PARAMETERS_NOT_OBJECT = "ParametersNotAsObject";
  static AUTH_PARAMETERS_INVALID = "InvalidAuthorizeParameters";
  static AUTH_MODULE_NOT_EXISTS = "InvalidAuthorizeParameters";
  static AUTH_WEBSITEUSER_PARAMS = "AuthWebsiteUserInvalidParameters";
  static AUTH_WEBSITEOAUTH_PARAMS = "AuthWebsiteOAuthInvalidParameters";
  static AUTH_WEBSITELINK_PARAMS = "AuthWebsiteLinkInvalidParameters";
  static AUTH_WEBSITEREFRESH_PARAMS = "AuthWebsiteRefreshInvalidParameters";
  static INVALID_TOKEN = "InvalidAuthorizationToken";
  static INVALID_VERSION = "InvalidVersion";
  static NETWORK_ERROR = "NetworkError";
  static API_SERVER_ERROR = "ApiServerError";
  static AUTH_SELLERUSER_PARAMS = "AuthSellerUserInvalidParameters";
  static AUTH_SELLEROAUTH_PARAMS = "AuthSellerOAuthInvalidParameters";
  static AUTH_SELLEREXCHANGE_PARAMS = "AuthSellerOAuthInvalidParameters";
  static AUTH_ADMINADMIN_PARAMS = "AuthAdminAdminInvalidParameters";
}

/**
 * Titles
 */
class Titles {
  static INVALID_CONFIG = "Invalid Configuration";
  static INVALID_ENDPOINT = "Invalid Endpoint";
  static INVALID_HEADER = "Invalid Header";
  static INVALID_HTTP_CONFIG = "Invalid Configuration";
  static INVALID_PARAMETER = "Invalid Parameter";
  static INVALID_TOKEN = "Invalid Authorization Token";
  static INVALID_VERSION = "Invalid Version";
  static NETWORK_ERROR = "Network Error";
  static API_SERVER_ERROR = "API Server Error";
}

/**
 * Messages
 */
class Messages {
  static INVALID_CONFIG = "Invalid client configuration provided";
  static INVALID_ENDPOINT = "Invalid API endpoint '%s' provided";
  static INVALID_HEADER = "Invalid HTTP header '%s' provided";
  static INVALID_HTTP_CONFIG = "Invalid HTTP client configuration";
  static INVALID_PARAMETER = "Invalid input parameter provided";
  static PARAMETERS_NOT_OBJECT = "Request parameters are not a valid object";
  static AUTH_PARAMETERS_INVALID =
    "Authorize requires a valid API key and authorizarion module name";
  static AUTH_MODULE_NOT_EXISTS = "Auth module '%s' is not implemented";
  static AUTH_WEBSITEUSER_PARAMS =
    "Authorization module WEBSITE/USER requires a valid username and password";
  static AUTH_WEBSITEOAUTH_PARAMS =
    "Authorization module WEBSITE/OAUTH requires a valid authProvider and authProviderToken";
  static AUTH_WEBSITELINK_PARAMS =
    "Authorization module WEBSITE/LINK requires a valid authProvider, authProviderToken and currentToken";
  static AUTH_WEBSITEREFRESH_PARAMS =
    "Authorization module WEBSITE/REFRESH requires a valid currentToken";
  static INVALID_TOKEN = "Invalid authorization JWT token provided";
  static INVALID_VERSION = "Invalid API version '%s' provided";
  static NETWORK_ERROR = "Network request error";
  static API_SERVER_ERROR = "Unknown API server error response received";
  static AUTH_SELLERUSER_PARAMS =
    "Authorization module SELLER/USER requires a valid username and password";
  static AUTH_SELLEROAUTH_PARAMS =
    "Authorization module SELLER/OAUTH requires a valid authProvider and authProviderToken";
  static AUTH_SELLEREXCHANGE_PARAMS =
    "Authorization module SELLER/EXCHANGE requires a valid currentToken";
  static AUTH_ADMINADMIN_PARAMS =
    "Authorization module ADMIN/ADMIN requires a valid username and password";
}

export { ErrorDetails, Codes, Types, Titles, Messages };
