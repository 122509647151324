import { IApiError } from "@riseart/interfaces";
import { AxiosRequestConfig } from "axios";
import {
  Codes as ErrorCodes,
  Types as ErrorTypes,
  Titles as ErrorTitles,
  Messages as ErrorMessages,
} from "./ErrorDefinitions";

/**
 * InvalidHttpClientConfiguration
 */
class InvalidHttpClientConfiguration extends Error implements IApiError {
  public previous: any;
  public code: string;
  public type: string;
  public status: number;
  public title: string;
  public detail: string;
  public config?: AxiosRequestConfig | undefined;

  /**
   * Constructor
   *
   * @param {Record<string, any> | string} previous
   */
  constructor(previous?: Record<string, any> | string) {
    // Error message
    const message = previous
      ? typeof previous === "string"
        ? previous
        : previous.message || ErrorMessages.INVALID_HTTP_CONFIG
      : ErrorMessages.INVALID_HTTP_CONFIG;

    // Parent constructor
    super(message);

    // Set the prototype explicitly
    Object.setPrototypeOf(this, InvalidHttpClientConfiguration.prototype);

    // Set error properties
    this.name = this.constructor.name;
    this.code =
      typeof previous === "object"
        ? previous.code || ErrorCodes.INVALID_HTTP_CONFIG
        : ErrorCodes.INVALID_HTTP_CONFIG;
    this.type = ErrorTypes.INVALID_HTTP_CONFIG;
    this.status = 0;
    this.title = ErrorTitles.INVALID_HTTP_CONFIG;
    this.detail = message;
    this.previous = previous;
    this.config = typeof previous === "object" ? previous.config : undefined;
  }
}

export { InvalidHttpClientConfiguration };
