import { IApiError } from "@riseart/interfaces";
import {
  Codes as ErrorCodes,
  Types as ErrorTypes,
  Titles as ErrorTitles,
  Messages as ErrorMessages,
} from "./ErrorDefinitions";

/**
 * InvalidConfiguration
 */
class InvalidConfiguration extends Error implements IApiError {
  public previous: any;
  public code: string;
  public type: string;
  public status: number;
  public title: string;
  public detail: string;

  /**
   * Constructor
   *
   * @param {Record<string, any> | string} previous
   */
  constructor(previous?: Record<string, any> | string) {
    // Error message
    const message = previous
      ? typeof previous === "string"
        ? previous
        : previous.message || ErrorMessages.INVALID_CONFIG
      : ErrorMessages.INVALID_CONFIG;

    // Parent constructor
    super(message);

    // Set the prototype explicitly
    Object.setPrototypeOf(this, InvalidConfiguration.prototype);

    // Set error properties
    this.name = this.constructor.name;
    this.code = ErrorCodes.INVALID_CONFIG;
    this.type = ErrorTypes.INVALID_CONFIG;
    this.status = 0;
    this.title = ErrorTitles.INVALID_CONFIG;
    this.detail = message;
    this.previous = previous;
  }
}

export { InvalidConfiguration };
