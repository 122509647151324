import { IApiError } from "@riseart/interfaces";
import {
  Codes as ErrorCodes,
  Types as ErrorTypes,
  Titles as ErrorTitles,
  Messages as ErrorMessages,
} from "./ErrorDefinitions";

/**
 * InvalidParameter
 */
class InvalidParameter extends Error implements IApiError {
  public previous: any;
  public code: string;
  public type: string;
  public status: number;
  public title: string;
  public detail: string;

  /**
   * Constructor
   *
   * @param {string} type
   * @param {string} detail
   * @param {Record<string, any> | string} previous
   */
  constructor(
    type?: string,
    detail?: string,
    previous?: Record<string, any> | string
  ) {
    // Error message
    const message = previous
      ? typeof previous === "string"
        ? previous
        : previous.message || ErrorMessages.INVALID_PARAMETER
      : ErrorMessages.INVALID_PARAMETER;

    // Parent constructor
    super(detail || message);

    // Set the prototype explicitly
    Object.setPrototypeOf(this, InvalidParameter.prototype);

    // Set error properties
    this.name = this.constructor.name;
    this.code = ErrorCodes.INVALID_PARAMETER;
    this.type = type || ErrorTypes.INVALID_PARAMETER;
    this.status = 0;
    this.title = ErrorTitles.INVALID_PARAMETER;
    this.detail = detail || message;
    this.previous = previous;
  }
}

export { InvalidParameter };
