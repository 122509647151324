import { IApiError } from "@riseart/interfaces";
import { AxiosRequestConfig, AxiosError } from "axios";
import {
  Codes as ErrorCodes,
  Types as ErrorTypes,
  Titles as ErrorTitles,
  Messages as ErrorMessages,
} from "./ErrorDefinitions";

/**
 * NetworkError
 */
class NetworkError extends Error implements IApiError {
  public previous: any;
  public code: string;
  public type: string;
  public status: number;
  public title: string;
  public detail: string;
  public config?: AxiosRequestConfig | null;
  public request?: any;

  /**
   * Constructor
   *
   * @param {AxiosError} previous
   */
  constructor(previous?: AxiosError) {
    // Error message
    const message =
      (previous ? previous.message : undefined) || ErrorMessages.NETWORK_ERROR;

    // Parent constructor
    super(message);

    // Set the prototype explicitly
    Object.setPrototypeOf(this, NetworkError.prototype);

    // Set error properties
    this.name = this.constructor.name;
    this.code =
      (previous ? previous.code : undefined) || ErrorCodes.NETWORK_ERROR;
    this.type = ErrorTypes.NETWORK_ERROR;
    this.status = 0;
    this.title = ErrorTitles.NETWORK_ERROR;
    this.detail = message;
    this.previous = previous;
    this.config = previous ? previous.config : undefined;
    this.request = previous ? previous.request : undefined;
  }
}

export { NetworkError };
