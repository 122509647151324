export enum HTTP {
  RESPONSE_CODE_SUCCESS = 200,
  RESPONSE_CODE_CREATED = 201,
  RESPONSE_CODE_DELETED = 204,
  RESPONSE_CODE_UNAUTHORIZED = 401,
  RESPONSE_CODE_FORBIDDEN = 403,
  RESPONSE_CODE_RESOURCE_ALREADY_EXIST = 409,
  RESPONSE_CODE_INTERNAL_SERVER_ERROR = 500,
  METHOD_GET = "GET",
  METHOD_POST = "POST",
  METHOD_DELETE = "DELETE",
  METHOD_PUT = "PUT",
}
