import {
  ApiRequestBaseParams,
  ApiRequestParams,
  RequestHookResponseType,
} from "../types/types";
import { HTTP_METHOD } from "../config";
import { useContext, useState, useRef, useMemo, useEffect } from "react";
import { ApiResponse } from "@riseart/client";
import { RiseArtApiClientContext } from "../context/Context";

/**
 * useRequest
 *
 * @param {Record<string, any>} params
 * @returns {RequestHookResponseType}
 */
export function useRequest({
  endpoint,
  resourceId = null,
  parameters,
  version = null,
  method = HTTP_METHOD.GET,
  skip = false,
  config = null,
}: ApiRequestParams): RequestHookResponseType {
  const { client } = useContext(RiseArtApiClientContext);
  const [data, setData] = useState<ApiResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const instance = useMemo(() => client, [client]);

  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  // { endpoint, parameters }
  async function processRequest(requestParams?: ApiRequestBaseParams) {
    try {
      if (!instance) {
        throw new Error("Inalid API client provided.");
      }

      setError(null);
      setLoading(true);

      // @ts-ignore
      const response = await instance[method]({
        signal: controllerRef.current.signal,
        endpoint: (requestParams && requestParams.endpoint) || endpoint,
        parameters: (requestParams && requestParams.parameters) || parameters,
        version: (requestParams && requestParams.version) || version,
        ...((requestParams && requestParams.resourceId) || resourceId
          ? {
              resourceId:
                (requestParams && requestParams.resourceId) || resourceId,
            }
          : null),
        ...((requestParams && requestParams.config) || config
          ? {
              config: (requestParams && requestParams.config) || config,
            }
          : null),
      });

      if (response) {
        setData(response);

        return response;
      }
    } catch (error: any) {
      setError(error);

      throw error;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    !skip && processRequest({ endpoint, resourceId, parameters, version });
  }, [
    endpoint,
    resourceId,
    parameters && JSON.stringify(parameters),
    version,
    method,
    skip,
  ]);

  return { cancel, data, error, loading, processRequest };
}
