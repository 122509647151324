import Axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import { sprintf } from "sprintf-js";
import {
  ApiServerError,
  InvalidConfiguration,
  InvalidEndpoint,
  InvalidHeader,
  InvalidHttpClientConfiguration,
  InvalidParameter,
  InvalidToken,
  InvalidVersion,
  NetworkError,
  ErrorDetails,
  Types as ErrorTypes,
  Messages as ErrorMessages,
} from "./errors";
import { ApiResponse } from "./response/ApiResponse";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const DEFAULT_API_VERSION = "v1";
const DEFAULT_AUTH_ENDPOINT = "auth";
const TOKEN_BEARER_PREFIX = "Bearer";
const DEFAULT_HTTP_REQUEST_TIMEOUT = 30000;

// Module enums
enum AUTH_MODULES {
  APPLICATION = "application",
  WEBSITE_VISITOR = "website/visitor",
  WEBSITE_USER = "website/user",
  WEBSITE_OAUTH = "website/oauth",
  WEBSITE_LINK = "website/link",
  WEBSITE_REFRESH = "website/refresh",
  SELLER_USER = "seller/user",
  SELLER_OAUTH = "seller/oauth",
  SELLER_EXCHANGE = "seller/exchange",
  ADMIN_ADMIN = "admin/admin",
}
enum OAUTH_PROVIDERS {
  FACEBOOK = "facebook",
  GOOGLE = "google",
}

/**
 * Client
 */
class Client {
  private apiHost: string;
  private apiVersion: string;
  private apiAuthEndpoint: string;
  private token: string | null;
  private clientConfig: ClientConfig;
  private httpClient: AxiosInstance;

  /**
   * Constructor
   *
   * @param {ClientConfig} config
   */
  constructor(config: ClientConfig) {
    const { apiHost, apiVersion, apiAuthEndpoint } = config;
    this.apiHost = apiHost;
    this.apiVersion = apiVersion ? apiVersion : DEFAULT_API_VERSION;
    this.apiAuthEndpoint = apiAuthEndpoint
      ? apiAuthEndpoint
      : this.endpointAssembler({ endpoint: DEFAULT_AUTH_ENDPOINT });
    this.clientConfig = this.validateCurrentConfig();
    this.httpClient = this.httpClientFactory();
    this.token = null;
  }

  /**
   * IsVersionValid
   *
   * @param {string} version
   */
  public static isVersionValid(version: string): boolean {
    const regex = new RegExp(/^v(\d+(\.\d+)*)$/g);
    if (regex.test(version)) {
      return true;
    }
    throw new InvalidVersion(version);
  }

  /**
   * SetToken
   *
   * @param {string} token
   */
  public setToken(token: string): void {
    if (!token) {
      throw new InvalidToken();
    }
    this.token = token;
    this.setHeader("Authorization", `${TOKEN_BEARER_PREFIX} ${this.token}`);
  }

  /**
   * SetHeader
   *
   * @param {string} header
   * @param {string} value
   */
  public setHeader(header: string, value: string): void {
    if (!header) {
      throw new InvalidHeader();
    }

    this.httpClient.defaults.headers.common[header] = value;
  }

  /**
   * GetToken
   *
   * @returns {string | boolean}
   */
  public getToken(): string | boolean {
    return this.token ? this.token : false;
  }

  /**
   * GetBaseUrl
   *
   * @param {string} version
   * @returns {string}
   */
  public getBaseUrl(version?: string): string {
    return `${this.apiHost}/${version ? version : this.apiVersion}/`;
  }

  /**
   * ObjectToUrl
   *
   * @param {Record<string, string | number | boolean> | null} parameters
   * @returns {string}
   */
  public parametersToUrl(
    parameters: Record<string, string | number | boolean> | null
  ): string {
    // Will reject empty parameters
    if (!parameters) return "";

    // Parameters is not an Object
    if (!this.isObject(parameters)) {
      throw new InvalidParameter(
        ErrorTypes.PARAMETERS_NOT_OBJECT,
        ErrorMessages.PARAMETERS_NOT_OBJECT
      );
    }

    // Parameters is a valid type but is empty
    if (
      (parameters instanceof Array && parameters.length === 0) ||
      Object.keys(parameters).length === 0
    )
      return "";

    // Build query string and return
    return parameters
      ? "?" +
          Object.keys(parameters)
            .map(
              (k) =>
                encodeURIComponent(k) + "=" + encodeURIComponent(parameters[k])
            )
            .join("&")
      : "";
  }

  /**
   * SetRequestInterceptor
   *
   * @param {(config: AxiosRequestConfig) => any} interceptor
   * @returns {number}
   */
  public setRequestInterceptor(
    interceptor: (config: AxiosRequestConfig) => any
  ): number {
    return this.httpClient.interceptors.request.use(interceptor);
  }

  /**
   * SetResponseInterceptor
   *
   * @param {(response: Record<string, any>) => any} responseCallback
   * @param {(error: Error) => any} errorCallback
   * @returns {number}
   */
  public setResponseInterceptor(
    responseCallback: (response: Record<string, any>) => any,
    errorCallback: (error: Error) => any
  ): number {
    return this.httpClient.interceptors.response.use(
      responseCallback,
      errorCallback
    );
  }

  /**
   * RemoveRequestInterceptor
   *
   * @param {number} interceptor
   * @returns {void}
   */
  public removeRequestInterceptor(interceptor: number): void {
    this.httpClient.interceptors.request.eject(interceptor);
  }

  /**
   * RemoveResponseInterceptor
   *
   * @param {number} interceptor
   * @returns {void}
   */
  public removeResponseInterceptor(interceptor: number): void {
    this.httpClient.interceptors.response.eject(interceptor);
  }

  /**
   * GET
   *
   * @param {string} endpoint
   * @param {string} resourceId
   * @param {Record<string, any>} parameters
   * @param {string} version
   * @returns {Promise<ApiResponse>}
   */
  public async GET(
    endpoint: string,
    resourceId?: string | number,
    parameters?: any, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    version?: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse> {
    // Endpoint configuration
    const endpointRequestInput: EndpointRequestInput = {
      endpoint,
      resourceId,
      parameters,
      version,
    };

    // Resolve
    return new Promise((resolve, reject) => {
      const requestEndpoint = this.endpointAssembler(endpointRequestInput);
      this.httpClient
        .get(requestEndpoint, config)
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });
  }

  /**
   * POST
   *
   * @param {string} endpoint
   * @param {Record<string, any>} parameters
   * @param {string} version
   * @returns {Prmoise<ApiResponse>}
   */
  public async POST(
    endpoint: string,
    parameters?: any, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    version?: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse> {
    // Endpoint configuration
    const endpointRequestInput: EndpointRequestInput = {
      endpoint,
      version,
    };

    // Check if parameters are valid
    if (!this.isObject(parameters)) {
      throw new InvalidParameter(
        ErrorTypes.PARAMETERS_NOT_OBJECT,
        ErrorMessages.PARAMETERS_NOT_OBJECT
      );
    }

    // Resolve
    return new Promise((resolve, reject) => {
      const requestEndpoint = this.endpointAssembler(endpointRequestInput);
      this.httpClient
        .post(requestEndpoint, parameters, config)
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });
  }

  /**
   * PUT
   *
   * @param {string} endpoint
   * @param {string | number} resourceId
   * @param {Record<string, any>} parameters
   * @param {string} version
   * @returns {Promise<ApiResponse>}
   */
  public async PUT(
    endpoint: string,
    resourceId?: string | number,
    parameters?: any, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    version?: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse> {
    // Endpoint configuration
    const endpointRequestInput: EndpointRequestInput = {
      endpoint,
      resourceId,
      version,
    };

    // Check if parameters are valid
    if (typeof parameters !== "undefined" && !this.isObject(parameters)) {
      throw new InvalidParameter(
        ErrorTypes.PARAMETERS_NOT_OBJECT,
        ErrorMessages.PARAMETERS_NOT_OBJECT
      );
    }

    // Resolve
    return new Promise((resolve, reject) => {
      const requestEndpoint = this.endpointAssembler(endpointRequestInput);
      this.httpClient
        .put(requestEndpoint, parameters, config)
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });
  }

  /**
   * DELETE
   *
   * @param {string} endpoint
   * @param {Record<string, any>} parameters
   * @param {string} version
   * @returns {Promise<ApiResponse>}
   */
  public async DELETE(
    endpoint: string,
    resourceId?: string | number,
    parameters?: any, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    version?: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse> {
    // Endpoint configuration
    const endpointRequestInput: EndpointRequestInput = {
      endpoint,
      resourceId,
      parameters,
      version,
    };

    // Resolve
    return new Promise((resolve, reject) => {
      const requestEndpoint = this.endpointAssembler(endpointRequestInput);
      this.httpClient
        .delete(requestEndpoint, config)
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });
  }

  /**
   * Authorize
   *
   * @param {AuthModulePayload} payload
   * @returns {Promise<string>}
   */
  public async authorize(payload: AuthModulePayload): Promise<string> {
    if (!payload.apiKey || !payload.authModule) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_PARAMETERS_INVALID,
        ErrorMessages.AUTH_PARAMETERS_INVALID
      );
    }

    switch (payload.authModule) {
      case AUTH_MODULES.WEBSITE_VISITOR:
        return this.authorizeVisitor(payload);

      case AUTH_MODULES.WEBSITE_USER:
        return this.authorizeUser(payload);

      case AUTH_MODULES.WEBSITE_OAUTH:
        return this.authorizeOauth(payload);

      case AUTH_MODULES.WEBSITE_LINK:
        return this.authorizeLink(payload);

      case AUTH_MODULES.WEBSITE_REFRESH:
        return this.authorizeRefresh(payload);

      case AUTH_MODULES.SELLER_USER:
        return this.authorizeSellerUser(payload);

      case AUTH_MODULES.SELLER_OAUTH:
        return this.authorizeSellerOauth(payload);

      case AUTH_MODULES.SELLER_EXCHANGE:
        return this.authorizeSellerExchange(payload);

      case AUTH_MODULES.ADMIN_ADMIN:
        return this.authorizeAdminAdmin(payload);

      case AUTH_MODULES.APPLICATION:
        return this.authorizeApplication(payload);

      default:
        throw new InvalidParameter(
          ErrorTypes.AUTH_MODULE_NOT_EXISTS,
          sprintf(ErrorMessages.AUTH_MODULE_NOT_EXISTS, payload.authModule)
        );
    }
  }

  /**
   * ParseError
   *
   * @param error
   * @returns {Error}
   */
  private parseError(error: any): Error {
    // Server replied with an error
    if (error.response) {
      return new ApiServerError(this.errorDetailsFactory(error));
    }

    // Request was made but not response received
    if (error.request) {
      return new NetworkError(error);
    }

    // This error is triggered if the client has a configuration problem
    return new InvalidHttpClientConfiguration(error);
  }

  /**
   * ErrorDetailsFactory
   *
   * @param error
   * @returns {ErrorDetails}
   */
  private errorDetailsFactory(error: AxiosError): ErrorDetails {
    const {
      data: responseData = undefined,
      status: responseStatus = undefined,
      headers: responseHeaders = undefined,
    } = error.response || {};
    const {
      url: requestUri,
      data: requestData,
      method: requestMethod,
    } = error.config;

    return {
      requestUri,
      requestMethod,
      requestData,
      responseStatus,
      responseHeaders,
      responseData,
      originalError: error,
    };
  }

  /**
   * EndpointAssembler
   *
   * @param {string} endpoint
   * @param {string} resourceId
   * @param {Array<any>} parameters
   * @param {string} version
   */
  private endpointAssembler(input: EndpointRequestInput): string {
    // Invalid endpoint
    if (!input.endpoint) {
      throw new InvalidEndpoint();
    }

    // Validate version
    if (input.version) Client.isVersionValid(input.version);

    // Clean up endpoint
    input.endpoint = input.endpoint.replace(/\/$/, "");

    // Encode and append the resource id to the url
    input.resourceId = input.resourceId
      ? "/" + encodeURIComponent(String(input.resourceId))
      : "";

    // Builds the request url
    // https://api.riseart.com/v1/ - arts/ - 4/ - ?parameter=value
    return `${this.getBaseUrl(input.version)}${input.endpoint}${
      input.resourceId
    }${this.parametersToUrl(input.parameters || null)}`;
  }

  /**
   * ValidateConfig
   *
   * @returns {ClientConfig}
   */
  private validateCurrentConfig(): ClientConfig {
    if (!this.apiHost) {
      throw new InvalidConfiguration();
    }

    return {
      apiHost: this.apiHost,
      apiVersion: this.apiVersion,
      apiAuthEndpoint: this.apiAuthEndpoint,
    };
  }

  /**
   * IsObject
   *
   * @param parameters
   * @returns {boolean}
   */
  private isObject(parameters: unknown): boolean {
    return typeof parameters === "object";
  }

  /**
   * HttpClientFactory
   *
   * @returns {AxiosInstance}
   */
  private httpClientFactory(): AxiosInstance {
    return Axios.create({
      baseURL: this.apiHost,
      headers: DEFAULT_HEADERS,
      timeout: DEFAULT_HTTP_REQUEST_TIMEOUT,
    });
  }

  /**
   * AuthorizeApplication
   *
   * @returns {Promise<string>}
   */
  private async authorizeApplication(
    payload: AuthModulePayload
  ): Promise<string> {
    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.APPLICATION, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeVisitor
   *
   * @returns {Promise<string>}
   */
  private async authorizeVisitor(payload: AuthModulePayload): Promise<string> {
    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.WEBSITE_VISITOR, // eslint-disable-line
          visitor_id: payload.visitorId || null, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeUser
   *
   * @returns {Promise<string>}
   */
  private async authorizeUser(payload: AuthModulePayload): Promise<string> {
    if (!payload.username || !payload.password) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_WEBSITEUSER_PARAMS,
        ErrorMessages.AUTH_WEBSITEUSER_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.WEBSITE_USER, // eslint-disable-line
          username: payload.username,
          password: payload.password,
          current_token: payload.currentToken || null, // eslint-disable-line
          quiz_response_id: payload.quizResponseId || null, // eslint-disable-line
          store_code: payload.storeCode || null, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeOauth
   *
   * @returns {Promise<string>}
   */
  private async authorizeOauth(payload: AuthModulePayload): Promise<string> {
    if (!payload.authProvider || !payload.authProviderToken) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_WEBSITEOAUTH_PARAMS,
        ErrorMessages.AUTH_WEBSITEOAUTH_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.WEBSITE_OAUTH, // eslint-disable-line
          auth_provider: payload.authProvider, // eslint-disable-line
          auth_provider_token: payload.authProviderToken, // eslint-disable-line
          current_token: payload.currentToken || null, // eslint-disable-line
          quiz_response_id: payload.quizResponseId || null, // eslint-disable-line
          referrer_id: payload.referrerId || null, // eslint-disable-line
          marketing_campaign_id: payload.marketingCampaignId || null, // eslint-disable-line
          store_code: payload.storeCode || null, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeLink
   *
   * @returns {Promise<string>}
   */
  private async authorizeLink(payload: AuthModulePayload): Promise<string> {
    if (
      !payload.authProvider ||
      !payload.authProviderToken ||
      !payload.currentToken
    ) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_WEBSITELINK_PARAMS,
        ErrorMessages.AUTH_WEBSITELINK_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.WEBSITE_LINK, // eslint-disable-line
          auth_provider: payload.authProvider, // eslint-disable-line
          auth_provider_token: payload.authProviderToken, // eslint-disable-line
          current_token: payload.currentToken, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeRefresh
   *
   * @returns {Promise<string>}
   */
  private async authorizeRefresh(payload: AuthModulePayload): Promise<string> {
    if (!payload.currentToken) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_WEBSITEREFRESH_PARAMS,
        ErrorMessages.AUTH_WEBSITEREFRESH_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.WEBSITE_REFRESH, // eslint-disable-line
          current_token: payload.currentToken, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeSellerUser
   *
   * @returns {Promise<string>}
   */
  private async authorizeSellerUser(
    payload: AuthModulePayload
  ): Promise<string> {
    if (!payload.username || !payload.password) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_SELLERUSER_PARAMS,
        ErrorMessages.AUTH_SELLERUSER_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.SELLER_USER, // eslint-disable-line
          username: payload.username,
          password: payload.password,
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeSellerOauth
   *
   * @returns {Promise<string>}
   */
  private async authorizeSellerOauth(
    payload: AuthModulePayload
  ): Promise<string> {
    if (!payload.authProvider || !payload.authProviderToken) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_SELLEROAUTH_PARAMS,
        ErrorMessages.AUTH_SELLEROAUTH_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.SELLER_OAUTH, // eslint-disable-line
          auth_provider: payload.authProvider, // eslint-disable-line
          auth_provider_token: payload.authProviderToken, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeSellerExchange
   *
   * @returns {Promise<string>}
   */
  private async authorizeSellerExchange(
    payload: AuthModulePayload
  ): Promise<string> {
    if (!payload.currentToken) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_SELLEREXCHANGE_PARAMS,
        ErrorMessages.AUTH_SELLEREXCHANGE_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.SELLER_EXCHANGE, // eslint-disable-line
          current_token: payload.currentToken, // eslint-disable-line
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }

  /**
   * AuthorizeAdminAdmin
   *
   * @returns {Promise<string>}
   */
  private async authorizeAdminAdmin(
    payload: AuthModulePayload
  ): Promise<string> {
    if (!payload.username || !payload.password) {
      throw new InvalidParameter(
        ErrorTypes.AUTH_ADMINADMIN_PARAMS,
        ErrorMessages.AUTH_ADMINADMIN_PARAMS
      );
    }

    const result: ApiResponse = await new Promise((resolve, reject) => {
      this.httpClient
        .post(this.apiAuthEndpoint, {
          api_key: payload.apiKey, // eslint-disable-line
          auth_module: AUTH_MODULES.ADMIN_ADMIN, // eslint-disable-line
          username: payload.username,
          password: payload.password,
        })
        .then((result) => resolve(new ApiResponse(result)))
        .catch((error) => reject(this.parseError(error)));
    });

    return result.data.token;
  }
}

/**
 * AuthModulePayload
 */
type AuthModulePayload = {
  apiKey: string;
  authModule: string;
  username?: string;
  password?: string;
  visitorId?: number;
  authProvider?: string;
  authProviderToken?: string;
  currentToken?: string;
  quizResponseId?: number;
  referrerId?: number;
  marketingCampaignId?: number;
  storeCode?: string;
};

/**
 * ClientConfig
 */
type ClientConfig = {
  apiHost: string;
  apiAuthEndpoint?: string;
  apiVersion?: string;
};

/**
 * EndpointRequestInput
 */
type EndpointRequestInput = {
  endpoint: string;
  resourceId?: string | number;
  parameters?: Record<string, string | number | boolean>;
  version?: string;
};

export {
  Client,
  ClientConfig,
  AuthModulePayload,
  AUTH_MODULES,
  OAUTH_PROVIDERS,
};
