import { IApiError } from "@riseart/interfaces";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { HTTP } from "../http/Http";
import {
  Codes as ErrorCodes,
  Types as ErrorTypes,
  Titles as ErrorTitles,
  Messages as ErrorMessages,
  ErrorDetails,
} from "./ErrorDefinitions";

/**
 * ApiServerError
 */
class ApiServerError extends Error implements IApiError {
  public previous: any;
  public code: string;
  public type: string;
  public status: number;
  public title: string;
  public detail: string;
  public additional?: Record<string, any>;
  public validation?: Array<any>;
  public trace?: Array<any>;
  public config?: AxiosRequestConfig;
  public request?: any;
  public response?: AxiosResponse;

  /**
   * Constructor
   *
   * @param {ErrorDetails} details
   */
  constructor(details: ErrorDetails) {
    // API error & message
    const apiError = details.responseData
      ? details.responseData.error
      : undefined;
    const message =
      (apiError ? apiError.detail : undefined) ||
      (details.originalError ? details.originalError.message : undefined) ||
      ErrorMessages.API_SERVER_ERROR;

    // Parent constructor
    super(message);

    // Set the prototype explicitly
    Object.setPrototypeOf(this, ApiServerError.prototype);

    // API response error properties
    this.name = this.constructor.name;
    this.code =
      (apiError ? apiError.code : undefined) ||
      (details.originalError ? details.originalError.code : undefined) ||
      ErrorCodes.API_SERVER_ERROR;
    this.type =
      (apiError ? apiError.type : undefined) || ErrorTypes.API_SERVER_ERROR;
    this.status =
      (details.responseStatus
        ? typeof details.responseStatus === "string"
          ? parseInt(details.responseStatus, 10)
          : details.responseStatus
        : undefined) || HTTP.RESPONSE_CODE_INTERNAL_SERVER_ERROR;
    this.title =
      (apiError ? apiError.title : undefined) || ErrorTitles.API_SERVER_ERROR;
    this.detail = message;
    this.additional = apiError ? apiError.additional : undefined;
    this.validation = apiError ? apiError.validationMessages : undefined;
    this.trace = apiError ? apiError.trace : undefined;

    // Parse Axios error
    this.previous = details.originalError;
    this.config = details.originalError
      ? details.originalError.config
      : undefined;
    this.request = details.originalError
      ? details.originalError.request
      : undefined;
    this.response = details.originalError
      ? details.originalError.response
      : undefined;
  }
}

export { ApiServerError };
