import {
  Client as RiseArtClient,
  ClientConfig,
  AuthModulePayload,
  ApiResponse,
} from "@riseart/client";

class ApiClient {
  /**
   * clientInstance
   */
  private static clientInstance: RiseArtClient;

  constructor(
    { apiHost, version }: Record<string, any>,
    interceptors?: {
      request: Array<(request: Record<string, any>) => any>;
      response: Array<{
        success: (request: Record<string, any>) => any;
        error: (request: Record<string, any>) => any;
      }>;
    }
  ) {
    // Set configuration
    const config: ClientConfig = { apiHost, apiVersion: version };

    // Create new client instance
    const client: RiseArtClient =
      ApiClient.clientInstance || new RiseArtClient(config);

    ApiClient.clientInstance = client;

    // Request interceptors
    interceptors &&
      interceptors.request &&
      interceptors.request.forEach((requestCallback) => {
        // @ts-ignore
        ApiClient.clientInstance.setRequestInterceptor(requestCallback);
      });

    // Response interceptors
    interceptors &&
      interceptors.response &&
      interceptors.response.forEach(({ success, error }) => {
        // @ts-ignore
        ApiClient.clientInstance.setResponseInterceptor(success, error);
      });
  }

  /**
   * getInstance
   *
   * @returns {ApolloClient} ApolloClient instance
   */
  public static getInstance(): RiseArtClient {
    return ApiClient.clientInstance;
  }

  /**
   * authorize
   *
   * @param {AuthModulePayload} payload
   * @returns {Promise<string>}
   */
  public async authorize(payload: AuthModulePayload): Promise<string> {
    const client: RiseArtClient | null = ApiClient.getInstance();

    const token = await client.authorize(payload);

    if (token) {
      client.setToken(token);
    }

    return token;
  }

  /**
   * get
   *
   * @param {Record<string, any>}  properties
   * @returns {Promise<ApiResponse>}
   */
  public async get({
    endpoint,
    resourceId,
    parameters,
    version,
    config,
  }: Record<string, any>): Promise<ApiResponse> {
    const client: RiseArtClient | null = ApiClient.getInstance();

    return await client.GET(endpoint, resourceId, parameters, version, config);
  }

  /**
   * post
   *
   * @param {Record<string, any>}  properties
   * @returns {Promise<ApiResponse>}
   */
  public async post({
    endpoint,
    parameters,
    version,
    config,
  }: Record<string, any>): Promise<ApiResponse> {
    const client: RiseArtClient | null = ApiClient.getInstance();

    return await client.POST(endpoint, parameters, version, config);
  }

  /**
   * put
   *
   * @param {Record<string, any>}  properties
   * @returns {Promise<ApiResponse>}
   */
  public async put({
    endpoint,
    resourceId,
    parameters,
    version,
    config,
  }: Record<string, any>): Promise<ApiResponse> {
    const client: RiseArtClient | null = ApiClient.getInstance();

    return await client.PUT(endpoint, resourceId, parameters, version, config);
  }

  /**
   * delete
   *
   * @param {Record<string, any>}  properties
   * @returns {Promise<ApiResponse>}
   */
  public async delete({
    endpoint,
    resourceId,
    parameters,
    version,
    config,
  }: Record<string, any>): Promise<ApiResponse> {
    const client: RiseArtClient | null = ApiClient.getInstance();

    return await client.DELETE(
      endpoint,
      resourceId,
      parameters,
      version,
      config
    );
  }
}

export { ApiClient };
