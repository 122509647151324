import { ApiMethodParams } from "../types/types";
import { HTTP_METHOD } from "../config";
import { useRequest } from "./request";

/**
 * useDelete
 *
 * @param {ApiMethodParams} params
 * @returns {Record<string, any>[]}
 */
export function useDelete({
  endpoint,
  resourceId = null,
  parameters,
  version = null,
  skip = true,
  config = null,
}: ApiMethodParams): Record<string, any>[] {
  const { cancel, data, error, loading, processRequest } = useRequest({
    endpoint,
    resourceId,
    parameters,
    version,
    method: HTTP_METHOD.DELETE,
    skip,
    config,
  });

  return [processRequest, { data, error, loading, cancel }];
}
