import { ApiMethodParams } from "../types/types";
import { HTTP_METHOD } from "../config";
import { useRequest } from "./request";

/**
 * useGet
 *
 * @param {ApiMethodParams} params
 * @returns {Record<string, any>}
 */
export function useGet({
  endpoint,
  resourceId = null,
  parameters,
  version = null,
  skip = false,
  config = null,
}: ApiMethodParams): Record<string, any> {
  const { cancel, data, error, loading, processRequest } = useRequest({
    endpoint,
    resourceId,
    parameters,
    version,
    method: HTTP_METHOD.GET,
    skip,
    config,
  });

  return {
    cancel,
    data: (data && data.data) || data,
    error,
    loading,
    processRequest,
  };
}
