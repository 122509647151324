import React from "react";
import { ApiClient } from "../client";

export interface ApiClientContext {
  client?: ApiClient;
}

/**
 * RiseArtApiClientContext
 *
 * Creates the context for API client
 */
export const RiseArtApiClientContext = React.createContext<ApiClientContext>(
  {}
);
