/**
 * ApiResponse
 */
export class ApiResponse {
  data: any;
  response: any;

  /**
   * Constructor
   *
   * @param {Record<string, any>} response
   */
  constructor(response: Record<string, any>) {
    this.data = response.data;
    this.response = response;
  }
}
