import { ReactChild } from "react";
import { RiseArtApiClientContext, ApiClientContext } from "./Context";

interface Props extends ApiClientContext {
  children: ReactChild;
}

/**
 * RiseArtApiClientProvider
 */
export const RiseArtApiClientProvider = ({
  client,
  children,
}: Props): JSX.Element => (
  <RiseArtApiClientContext.Provider value={{ client }}>
    {children}
  </RiseArtApiClientContext.Provider>
);
